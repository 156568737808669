'use strict'

const wixDataSchemasFunctions = ['list', 'bulkGet']

const objectWrapper = (sourceObj, functionsToWrap, wrapperFn) => {
  const wrappedObj = {
    ...sourceObj,
  }
  functionsToWrap.forEach(fName => {
    wrappedObj[fName] = wrapperFn((...args) => sourceObj[fName](...args))
  })
  return wrappedObj
}

const wixDataSchemaFunctionWrapper = objectWrapper

export default (wixDataCodeZone, wixDataCreator) => {
  const { wixDataSchemas } = wixDataCreator()

  return {
    wixDataSchemasProxy: wixDataSchemaFunctionWrapper(
      wixDataSchemas,
      wixDataSchemasFunctions,
      wixDataCodeZone,
    ),
  }
}
