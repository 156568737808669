/* global self */
/* global VERBOSE */
/* global DEV_MODE */
'use strict'

import '../helpers/polyfills'
import { get, noop } from 'lodash-es'
import parseUrl from 'url-parse'
import wixDataProxyCreator from '../wix-data/wixDataProxy'
import { traceCreators, logger as loggerCreator } from '../logger'
import { isEnvEditor, isEnvLive } from '../helpers/viewMode'
import FesDataFetcher from '../inverted-dependencies/FesDataFetcher'
import WixDataFetcher from '../inverted-dependencies/WixDataFetcher'
import DataCache from '../inverted-dependencies/DataCache'
import Features from '../inverted-dependencies/Features'
import DataBinding from './DataBinding'
import schemaAPICreator from '../schemas/schemaAPI'
import { createDataSchemasClientForBrowser } from '@wix/wix-data-schemas-client'
import { viewerAutomationsClientCreator } from '@wix/wix-code-automations-client'
import { APP_NAME } from '../helpers/constants'

export default class App {
  constructor({
    //TODO: all this crap is in constructor, because it can be passed from IT tests. AAAAAAA!!!!!
    elementorySupport = global.elementorySupport,
    wixDataCreator = ({ instance, gridAppId, baseUrl, envIsEditor }) => ({
      wixDataSchemas: createWixDataSchemasClient(
        instance,
        gridAppId,
        baseUrl,
        envIsEditor,
      ),
    }),
    errorReporter = (message, error) => console.error(message, error), // eslint-disable-line no-console
    verboseReporter = (...args) => console.verbose(...args), // eslint-disable-line no-console
    shouldVerbose = Boolean(VERBOSE),
    appLogger = loggerCreator({
      global: self,
      appName: APP_NAME,
    }),
    automationsClientCreator = ({ getHeaders }) =>
      viewerAutomationsClientCreator({
        requestFunction: global.fetch,
        getHeaders,
      }),
    getElementorySupport = () => global.elementorySupport,
  } = {}) {
    this.#appLogger = appLogger
    this.#wixDataCreator = wixDataCreator
    this.#getElementorySupport = getElementorySupport
    this.#errorReporter = errorReporter
    this.#originalVerboseReporter = verboseReporter
    this.#shouldVerbose = shouldVerbose
    this.#automationsClientCreator = automationsClientCreator

    return {
      initAppForPage: this.initAppForPage,
      createControllers: this.createControllers,
    }
  }

  initAppForPage = (
    { routerReturnedData, ...appParams },
    _,
    wixSdk,
    {
      bi: { pageId, viewerSessionId, pageUrl, metaSiteId, svSession } = {},
      reportTrace = noop,
      monitoring: { createMonitor: createRavenClient },
      fedOpsLoggerFactory,
      biLoggerFactory,
      essentials,
      getCsrfToken,
    } = {},
  ) => {
    try {
      const { instance, gridAppId } = extractInstanceAndGridAppId(
        appParams,
        this.#getElementorySupport(),
      )
      const viewMode = getViewMode(wixSdk)
      const isSSR = isSSRMode(wixSdk)
      const appState = {
        pageId,
        pageUrl,
        viewMode,
        metaSiteId,
        userId: svSession,
        sessionId: viewerSessionId,
        mode: {
          dev: DEV_MODE,
          ssr: isSSR,
          csr: !isSSR,
        },
        env: {
          live: isEnvLive(viewMode),
          editor: isEnvEditor(viewMode),
        },
      }

      const features = new Features({
        experiments: essentials.experiments,
        appState,
      })

      this.#appLogger.addSessionData(() => ({ routerReturnedData }))
      this.#appLogger.init({
        appLogger: this.#appLogger,
        user: {
          id: get(wixSdk, ['user', 'currentUser', 'id']),
        },
        inSsr: get(wixSdk, ['window', 'rendering', 'env']) === 'backend',
        viewMode,
        platformBiParams: { pageId, viewerSessionId },
        browserUrlGetter: () => get(wixSdk, ['location', 'url']),
        reportTrace,
        createRavenClient,
        fedOpsLoggerFactory,
        biLoggerFactory,
      })

      this.#appLogger.traceSync(traceCreators.initAppForPage(), () => {
        const { wixDataSchemasProxy } = wixDataProxyCreator(
          this.#appLogger.wixDataCodeZone,
          () => {
            return this.#wixDataCreator({
              instance,
              gridAppId,
              baseUrl: wixSdk.location.baseUrl,
              envIsEditor: isEnvEditor(viewMode),
            })
          },
        )

        this.#wixDataSchemasProxy = wixDataSchemasProxy
      })

      this.#schemaAPI = schemaAPICreator(
        this.#wixDataSchemasProxy,
        this.#appLogger,
      )

      const dataFetcher = features.fes
        ? new FesDataFetcher({
            httpClient: essentials.httpClient,
            getRequestParams: () => ({ instance, gridAppId }),
          })
        : new WixDataFetcher({
            wixData: wixSdk.data || self.require('wix-data').default,
            wixDataCodeZone: this.#appLogger.wixDataCodeZone,
          })

      const dataCache = new DataCache({
        warmupData: wixSdk.window?.warmupData,
      })

      this.#dataBinding = new DataBinding({
        appState,
        dataFetcher,
        dataCache,
        features,

        appLogger: this.#appLogger,
        errorReporter: this.#errorReporter,
        wixSdk,
        routerReturnedData,
        shouldVerbose: this.#shouldVerbose,
        originalVerboseReporter: this.#originalVerboseReporter,
        automationsClientCreator: () =>
          this.#automationsClientCreator({
            getHeaders: () => ({
              commonConfig: JSON.stringify(appParams.appData?.commonConfig),
              ...this.#getElementorySupport().options.headers,
              'XSRF-TOKEN': getCsrfToken(),
              Authorization: instance,
            }),
          }),
        schemaAPI: this.#schemaAPI,
      })

      return Promise.resolve()
    } catch (e) {
      this.#appLogger.error(e)
      return Promise.reject(e)
    }
  }

  createControllers = rawControllerConfigs => {
    return this.#appLogger.traceSync(traceCreators.createControllers(), () => {
      try {
        if (rawControllerConfigs.length === 0) {
          return []
        }

        return this.#dataBinding.initializeDatasets({ rawControllerConfigs })
      } catch (e) {
        this.#appLogger.error(e)
        return []
      }
    })
  }

  #dataBinding
  #appLogger
  #schemaAPI
  #wixDataSchemasProxy
  #wixDataCreator
  #getElementorySupport
  #errorReporter
  #originalVerboseReporter
  #shouldVerbose
  #automationsClientCreator
}

const isSSRMode = sdk => get(sdk, ['window', 'rendering', 'env']) === 'backend'
const getViewMode = sdk => get(sdk, ['window', 'viewMode'])

const extractInstanceAndGridAppId = (appParams, elementorySupport) => {
  if (appParams.instance && appParams.appData) {
    return {
      instance: appParams.instance,
      gridAppId: appParams.appData.gridAppId,
    }
  }

  const {
    query: { instance, gridAppId },
  } = parseUrl(`?${elementorySupport.queryParameters}`, true)

  return { instance, gridAppId }
}

const createWixDataSchemasClient = (
  instance,
  gridAppId,
  siteBaseUrl,
  envIsEditor,
) => {
  const { protocol, hostname } = parseUrl(siteBaseUrl)

  const baseUrl = envIsEditor
    ? undefined
    : `${protocol}//${hostname}/_api/cloud-data/v1/schemas`

  return createDataSchemasClientForBrowser(instance, gridAppId, {
    baseUrl,
  })
}
